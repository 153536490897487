exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[id]-[slug]-js": () => import("./../../../src/pages/blog/[id]/[slug].js" /* webpackChunkName: "component---src-pages-blog-[id]-[slug]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-emprendimientos-[id]-js": () => import("./../../../src/pages/emprendimientos/[id].js" /* webpackChunkName: "component---src-pages-emprendimientos-[id]-js" */),
  "component---src-pages-emprendimientos-index-js": () => import("./../../../src/pages/emprendimientos/index.js" /* webpackChunkName: "component---src-pages-emprendimientos-index-js" */),
  "component---src-pages-favoritos-js": () => import("./../../../src/pages/favoritos.js" /* webpackChunkName: "component---src-pages-favoritos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-propiedad-[operation]-[id]-js": () => import("./../../../src/pages/propiedad/[operation]/[id].js" /* webpackChunkName: "component---src-pages-propiedad-[operation]-[id]-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tasaciones-js": () => import("./../../../src/pages/tasaciones.js" /* webpackChunkName: "component---src-pages-tasaciones-js" */),
  "component---src-pages-tokko-[id]-js": () => import("./../../../src/pages/tokko/[id].js" /* webpackChunkName: "component---src-pages-tokko-[id]-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

