import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import './src/styles/styles.scss'
import './src/styles/fonts.css'
import './src/styles/slick.css'
// import './src/styles/newicons.css'

// export { default as wrapRootElement } from './src/redux/ReduxWrapper';
import wrapWithProvider  from './src/redux/ReduxWrapper'
export const wrapRootElement = wrapWithProvider
