import axios from 'axios'

// constantes
const dataInicial = {
    data:{
        nombre:'',
        apellido:'',
        cellphone:'',
        phone:'',
        email:'',
        work_name:'',
        text:'',
        properties:'',
        tags:'',
        message:''
    }
}

// types
const LOAD_FIELDS = 'LOAD_FIELDS'

// reducer
export default function contactReducer(state = dataInicial, action){
    switch(action.type){
        case LOAD_FIELDS:
            return {...state, data : action.payload }
        default:
            return state
    }
}

// actions

export const newSletterTokko = () => async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
        // Campos del formulario //
        var tags = ["Newsletter","Sitio Web"]
        const message =  JSON.stringify({
            email: fields.email,
            tags: tags
        });

        await axios.post('https://tokkobroker.com/api/v1/webcontact/?key=' + getState().settings.keys.tokko , message ,{
            headers: {
                'Content-Type': 'application/json'
            }
            })
    } catch (error) {
        console.log(error)
    }
}

export const formTokko = (tag,property = null,id = null, isDevelopment = false) => async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
        // Campos del formulario //
        let body = ''
        var name = ''
        var tags = [...tag,"Sitio Web"]
        if(property){tags.push(property)}
        if(fields.nombre){name+= fields.nombre}
        if(fields.apellido){name+=" " + fields.apellido}
        if(fields.telefono){body += "Teléfono: " + fields.telefono + "\n"}
        if(fields.cellphone){body += "Celular: " + fields.cellphone + "\n"}
        // if(fields.email){body += "\n Email: " + fields.email}
        // if(tag == 'Landing Web' || tag ==  'Ficha web unidad'){body += "Emprendimiento: " + property + "\n"}
        // if(tag == 'Propiedad' ){body += "Propiedad: " + property + "\n"; }
        body += "Email: " + fields.email + "\n"
        if(fields.text){ body += "Mensaje: " + fields.text + "\n"}
        
        var message = '' 

        if(isDevelopment){
            message =  JSON.stringify({
                name,
                email: fields.email,
                cellphone:fields.cellphone,
                tags,
                developments:id,
                text: body
            });
        }
        if(!isDevelopment){
            message =  JSON.stringify({
                name,
                email: fields.email,
                cellphone:fields.cellphone,
                tags,
                properties:id,
                text: body
            });
        }

        await axios.post('https://tokkobroker.com/api/v1/webcontact/?key=' + getState().settings.keys.tokko , message ,{
            headers: {
                'Content-Type': 'application/json'
            }
            })
    } catch (error) {
        console.log(error)
    }
}
export const formDiaryTokko = (data,tag,property = null,id = null, isDevelopment = false) => async (dispatch, getState) => {
    const fields = data
    console.log(data)
    try {
        // Campos del formulario //
        let body = ''
        var name = ''
        var tags = [...tag,"Agendar Visita"]
        if(property){tags.push(property)}
        if(data.nombre){name+= data.nombre}
        if(data.apellido){name+=" " + data.apellido}
        if(data.telefono){body += "Teléfono: " + data.telefono + "\n"}
        if(data.cellphone){body += "Celular: " + data.cellphone + "\n"}
        body += "Email: " + data.email + "\n"
        body += "Fecha sugerida para la visita: " + data.date.getDate() + "/" + (data.date.getMonth()+1) + "/" + data.date.getFullYear()  + "\n"
        if(data.time){body += "Turno: " + data.time + "\n"}
        if(data.text){ body += "Mensaje: " + data.text + "\n"}
        
        var message = '' 

        if(isDevelopment){
            message =  JSON.stringify({
                name,
                email: fields.email,
                cellphone:fields.cellphone,
                tags,
                developments:id,
                text: body
            });
        }
        if(!isDevelopment){
            message =  JSON.stringify({
                name,
                email: fields.email,
                cellphone:fields.cellphone,
                tags,
                properties:id,
                text: body
            });
        }


        await axios.post('https://tokkobroker.com/api/v1/webcontact/?key=' + getState().settings.keys.tokko , message ,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (error) {
        console.log(error)
    }
}

export const formTasaciones_Tokko = (type,operation,customs = []) => async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
        // Campos del formulario //
        let body = ''
        var tags = [...customs,"Tasación" , 'Sitio Web']
        body += "Nombre: " + fields.nombre
        body += "\n Email: " + fields.email
        body += "\n Ubicación: " + fields.ubicacion
        body += "\n Tipo: " + type
        body += "\n Operacion: " + operation
        if(fields.rooms){ body += "\n Dormitorios: " + fields.rooms}
        if(fields.message){ body += "\n Mensaje: " + fields.message}

        const message =  JSON.stringify({
            name:fields.nombre,
            email: fields.email,
            tags,
            text: body
        });
        // console.log(message)
        await axios.post('https://tokkobroker.com/api/v1/webcontact/?key=' + getState().settings.keys.tokko , message ,{
            headers: {
                'Content-Type': 'application/json'
            }
            })
    } catch (error) {
        console.log(error)
    }
}

export const updateFields = (datos) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LOAD_FIELDS,
            payload: datos
        })
    } catch (error) {
        console.log(error)
    }
}