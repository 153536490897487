export const getTextShort = (text,length) => {
    if(!text) return false
    return text.length > length ? text.substr(0,length) + '...' : text
}

export const getDateTime = (date,full = false) => {
    const Month = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
    }
    if(date){
        // const dateTime = date.split(':')
        // let times = dateTime[0].split(' ') 
        // times=times[0].split("-")
        // return full ? [times[2]] + " de " + Month[parseInt(times[1])]+ ' ' + times[0] : Month[parseInt(times[1])] + " " + times[0]
        const dateTime = date.split('T')
        let times = dateTime[0].split(' ') 
        times=times[0].split("-")
        return full ? [times[2]] + " de " + Month[parseInt(times[1])]+ ' ' + times[0] : Month[parseInt(times[1])] + " " + times[0]
    }

}

export const getCompleteName = (noveltie) => {
    return (noveltie?.creator?.first_name && 
            noveltie?.creator?.last_name)
            ? (`${noveltie?.creator?.first_name} ${noveltie?.creator?.last_name}`)
            : 'Anonimo';
}

export const getFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'true' || item.featured === '1' || item.featured === true)
    }
}

export const getNotFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'false' || item.featured === '0' || item.featured === false)
    }
}

export const getThree = (data) => {
    // Agarro los primeros 3 activos por position
    return data.filter((item,index) => item.is_active.toString() === "true" || item.active.toString() === "1").slice(0,3)
  }


export const orderNovelties = (novelties) => {
    const noveltiesPair = novelties;
    for(let i = 0; i < noveltiesPair.length; i++){
        const date = noveltiesPair[i].date_published.split("T")[0].split("-")
        noveltiesPair[i].parseDate = new Date(date[0], date[1]-1, date[2])
        // console.log(noveltiesPair[i].parseDate)
    }
    return(noveltiesPair.sort((a, b) => b.parseDate.getTime() - a.parseDate.getTime()))
}